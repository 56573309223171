.client-details {
    margin-top: 10px;
    padding: 10px;
    color:#848484;

    /* border: 1px solid #ddd; */
    /* border-radius: 4px; */
    height: 100%;
    /* position: fixed; */
    /* margin-top: 3rem; */
    /* margin-left: 15rem; */
    /* margin: 0 auto; */
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    /* height: 110%; */
    /* padding: 20px; */
    /* border-radius: 8px; */
    
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    /* margin-left: 0.30rem; */

    /* overflow-y: scroll; */
    box-sizing: content-box;
    /* margin-bottom: 5rem; */
    /* overflow-x: hidden; */

  }
  .client-details p {
    margin: 5px 0;
    font-size: 14px;
    /* color: #555; */
  }