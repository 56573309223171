.add-company-form {
    width: 100%;
    /* height: 100vh; */
    padding: 20px;
   font-family: sans-serif;

    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    /* overflow: hidden; */
    margin-left: 0.30rem;
   
}
.add-company-inner-form{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-top: 1rem;
}
.add-company-top {
    display: flex;
    justify-content: space-between;
    padding: 12px;
}

.form-group-add-company {
    margin-bottom: 15px;
    display: flex;
    /* flex-direction: column; */
    /* justify-content: center; */
    /* align-items: center; */
}

.form-group-add-company label {
    display: block;
    margin-bottom: 5px;
 
    font-size: 16px;
}

.input-field-add-company {
    width: 100%;
    /* padding: 12px; */
    border: 1px solid #ccc;
    /* border-radius: 8px; */
    font-size: 16px;
    /* transition: border-color 0.3s, box-shadow 0.3s; */
}

/* .input-field:focus {
    border-color: #007bff;
    box-shadow: 0 0 8px rgba(0, 123, 255, 0.5);
    outline: none;
} */

.textarea-address{
    max-height: 60%;
}
.submit-button-add-product {
    /* width: 11%; */
    font-size: 18px;
    /* background-color: #343a40; */
    background: #C6C5B9;

    /* color: white; */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    /* height: 3rem; */
    transition: background-color 0.3s ease;
    /* position: absolute; */
    /* margin-right:-1rem; */
    /* top: 2rem; */
}
/* 
.submit-button-add-product:hover {
    background-color: #007bff;
} */
/* 
.terms-container {
    margin-top: 20px;
    display: flex;
    width: 50%;
}

.term-entry {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.input-field-terms {
    margin-right: 10px;
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.add-term-btn {
    background-color: #343a40;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    padding: 10px;
    transition: background-color 0.3s ease;
}

.add-term-btn:hover {
    background-color: #007bff;
} */
.terms-container {
    margin-top: 1rem;
    margin-bottom: 20px;
    display: flex;
    /* justify-content: space-around; */
    justify-content: center;
    /* height: 5rem; */
    width: 100%;
  }
  
  .term-item {
    /* display: flex; */
    /* align-items: center; */
    margin-bottom: 10px; /* Adds space between each term */
    max-width: 100%;
    
  }
  
  .input-field-terms {
    width: 80%;
    /* flex: 1; */
    padding: 8px;
    border: 1px solid #ccc;
    /* border-radius: 4px; */
    margin-right: 10px; /* Adds space between the input and remove button */
  }
  
  .remove-button {
    background-color: #ff4d4d;
    color: white;
    border: none;
    padding: 6px 12px;
    /* height: 2rem; */
    cursor: pointer;
  }
  
  .add-button {
    /* background-color: #343a40; */
    background: #C6C5B9;

    /* color: white; */
    border: none;
    /* padding: 8px 25px; */
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px; /* Adds space above the Add Term button */
    margin-left: 90%;
    margin-right: -1rem;
    /* position: absolute;
    right: 21rem;
    top:18.25rem */
    
  }
  .terms-sec{
    width: 100%;
  }
  
  /* .submit-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
  } */
  
.input-remove{
    display: flex;
    width: 100%;
}
@media (min-width: 600px) {
    .form-group {
        flex-direction: row;
        justify-content: space-between;
    }

    label {
        flex: 1;
        margin-right: 16px;
    }

    .input-field {
        flex: 2;
    }
}