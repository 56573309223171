/* AddClient.css */

.add-client-container {
  /* margin-top: 2rem; */
  background-color: #f9f9f9;
  padding: 30px;
  width: 100%;
  /* height: 100vh; */
  /* overflow-y: hidden; */
  font-family: sans-serif;
  color: #ACACAC;
  /* margin: 40px auto; */
 
  /* position: fixed;
  top: 3.5rem;
  left: 15.5rem; */
}

.form-title {
  font-size: 24px;
  font-weight: bold;

  text-align: left;
  margin-bottom: 20px;
}

.error-message {
  color: red;
  font-size: 14px;
}

.success-message {
  color: green;
  font-size: 14px;
}

.form-container-addclient {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.form-section {
  display: flex;
  flex-direction: column;
}

.form-section label {
  /* font-weight: bold; */
  margin-bottom: 5px;
  /* color: #333; */
}

.form-section input {
  padding: 10px;
  border: 1px solid #ddd;
  /* font-size: 14px; */
  margin-bottom: 15px;
}


.selected-company {
  margin-top: 20px;
  font-weight: bold;
  color: #333;
}

.submit-btn {
  margin-top: 30px;
  /* background-color: #333; */
  /* color: rgb(49, 48, 48); */
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  display: block;
  width: 20%;
}


.btn-container-submit{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loader {
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
}



@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
