/* QuotationList Container */
.quotation-list-container {
    padding: 20px;
    margin: 0 auto;
    width:85%;
    background-color: #f4f4f4;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    height: 99vh;
    overflow-y: scroll;
    overflow-x: hidden;
    position: fixed;
    top: 2rem;
    /* display: flex;
    justify-content: center;
    align-items: center; */
  }
  
  /* Title */
  .quotation-list-container h2 {
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
  }
  
  /* Quotation Items List */
  .quotation-list {
    list-style: none;
    padding: 0;
  }
  
  .quotation-list-item {
    cursor: pointer;
    padding: 15px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #fff;
    transition: background-color 0.3s ease;
  }
  
  .quotation-list-item:hover {
    background-color: #f0f8ff;
  }
  
  .quotation-list-item strong {
    color: #333;
    display: inline-block;
    width: 120px;
  }
  
  .quotation-list-item span {
    color: #555;
    display: inline-block;
  }
  
  /* CreateQuotation Container when opened */
  .create-quotation-container {
    /* margin-top: 30px; */
    padding-left: 10px;
    /* padding-right: 20px; */
    
    /* border-top: 1px solid #ddd; */
    margin-left: -1.7rem;
    width: 96%;
  }

  .Create-quatation-QuatationList{
    width: 105%;
  }
 .loading{
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
 }