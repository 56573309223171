.create-user-form {
    /* width: 100%; */
    height: 100%;
    /* position: fixed; */
    /* margin-top: 3rem; */
    /* margin-left: 15rem; */
    /* margin: 0 auto; */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* height: 110%; */
    padding: 20px;
    border-radius: 8px;
    
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* margin-left: 0.30rem; */

    overflow-y: scroll;
    box-sizing: content-box;
    /* margin-bottom: 5rem; */
    /* overflow-x: hidden; */

  }
  .create-user-top{
    display: flex;
    justify-content: space-between;
    padding: 12px;
  }
  .create-user-top h2{
    font-size: larger;
    font-weight: 700;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    /* font-weight: 600; */
  }
  
  
  .submit-button {
    /* padding: 12px; */
    /* width: 11%; */
    font-size: 18px;
    /* background-color: #343a40; */
    color: #535353;

    border: none;
    border-radius: 5px;
    cursor: pointer;
    height: 3rem;
  }
  

  
  .form-group {
  display: flex;
  flex-direction: column; /* Stack label and input */
  margin-bottom: 16px; /* Spacing between form groups */
}

label {
  /* font-weight: bold; Bold label text */
  margin-bottom: 8px; /* Space between label and input */
  font-size: 16px; /* Font size for labels */
}
.input-field {
    /* width: 100%; */
    padding: 6px;
    border: 1px solid #ccc;
    /* border-radius: 8px; More rounded corners */
    font-size: 16px;
    /* transition: border-color 0.3s, box-shadow 0.3s; Smooth transitions */
    
  }
  
  /* .input-field:focus {
    border-color: #007bff; 
    box-shadow: 0 0 8px rgba(0, 123, 255, 0.5); 
    outline: none;
  } */
  
.personal-details{
    /* width: 100%; */
    padding: 1rem;
    display: flex;
    justify-content: space-between;
}
.input-sec{
    /* display: flex; */
    /* justify-content: center;
    align-items: center; */
    /* margin-bottom: 0.5rem;
    margin-right: 1rem; */
}

/* General dropdown styling */
.dropdown-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* flex-direction: column; */
    /* gap: 20px; */
  }
  
  .dropdown {
    position: relative;
    /* width: 27%; */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .dropdown-button {
    /* width: 80%; */
    padding: 10px;
    background-color: #343a40;
    color: white;
    border: none;
    /* border-radius: 5px; */
    cursor: pointer;
    font-size: 16px;
    /* transition: background-color 0.3s ease; */
  }
  
  
  .dropdown-content {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 100;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    background-color: #fff;
    border: 1px solid #ddd;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* border-radius: 5px; */
    padding: 10px;
 
  }
  
  .dropdown-content div {
    margin-bottom: 10px;
  }
  
  .dropdown-content label {
    cursor: pointer;
    font-size: 14px;
    /* color: #333; */
  }
  
  /* Selected values section */
  .selected-values {
    display: flex;
    justify-content: space-around;
    width: 100% ;
    /* margin-left: 10%; */
    /* position: absolute; */
  }
  
  .selected-permissions,
  .selected-companies {
    /* margin-bottom: 15px; */
    /* width: 30%; */
  }
  
  .selected-permissions h4,
  .selected-companies h4 {
    margin-bottom: 5px;
    font-size: 16px;
    /* color: #333; */
  }
  
  .selected-permissions ul,
  .selected-companies ul {
    list-style: none;
    padding: 0;
  }
  
  .selected-permissions li,
  .selected-companies li {
    background-color: #f0f0f0;
    border: 0.5px solid rgb(220, 213, 213);
    padding: 5px 10px;
    /* border-radius: 4px; */
    margin-bottom: 2px;
    font-size: 14px;
    display: inline-block;
    margin-right: 10px;
  }
  .permission-sec{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  

  
  ul {
    padding: 0;
  }
  
  li {
    list-style: none;
  }
  
  /* Hover effect for options */
  .dropdown-content label:hover {
    /* color: #007bff; */
  }
/* Styling for Role Section */
.role-section {
    margin-bottom: 20px;
  }
  
  /* Styling the label */
  .role-label {
    font-size: 16px;
    /* font-weight: 700; */
    /* color: #333; */
    display: block;
    margin-bottom: 8px;
  }
  .role-dropdown{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  /* Styling the dropdown */
  .dropdown-select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    /* border-radius: 5px; */
    background-color: #f9f9f9;
    font-size: 16px;
    /* color: #333; */
    cursor: pointer;
    /* transition: border-color 0.3s, box-shadow 0.3s; */
  }
  
  /* Focus and hover effects for the dropdown */
  .dropdown-select:focus, .dropdown-select:hover {
    /* border-color: #3498db;
    box-shadow: 0 0 8px rgba(52, 152, 219, 0.5);
    outline: none; */
  }
  
  /* Optional: Styling for dropdown items */
  .dropdown-select option {
    padding: 10px;
    background-color: #fff;
  }
  
  /* Media query for responsive design */
  /* @media (min-width: 768px) {
    .role-section {
      max-width: 400px;
      margin: 0 auto;
    }
  } */
  
  /* .work-details{
    display: flex;
  } */
  /* General styling for the dropdown sections */
.permissions-section {
    margin-bottom: 20px;
  }
  
  /* Styling for labels */
  .dropdown-label {
    font-size: 16px;
    /* font-weight: 500; */
    /* color: #333; */
    display: block;
    margin-bottom: 8px;
  }
  
  /* Common styles for dropdown button */
  .dropdown-button {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    /* border-radius: 5px; */
    background-color: #f9f9f9;
    font-size: 16px;
    color: #C6C5B9;
    cursor: pointer;
    /* transition: border-color 0.3s, box-shadow 0.3s; */
  }
  
  /* Focus and hover effects */
  .dropdown-button:focus, .dropdown-button:hover {
    /* border-color: #3498db;
    box-shadow: 0 0 8px rgba(52, 152, 219, 0.5);
    outline: none; */
  }
  
  /* Dropdown content styling */
  .dropdown-content {
    /* border: 1px solid #ccc;
    border-radius: 5px; */
    background-color: #fff;
    max-height: 200px; /* Limit height */
    overflow-y: auto; /* Add scroll if too many options */
    position: absolute; /* Positioning */
    z-index: 1; /* Ensure it appears above other elements */
  }
  
  /* Styling for individual options */
  .dropdown-content label {
    display: flex;
    align-items: center;
    padding: 8px;
  }
  
  .dropdown-content input[type="checkbox"] {
    margin-right: 8px; /* Space between checkbox and text */
  }
  

/* Responsive adjustments */
@media (min-width: 600px) {
  .form-group {
    flex-direction: row; /* Align items in a row on larger screens */
    justify-content: space-between; /* Space between label and input */
  }

  label {
    flex: 1; /* Allow label to take space */
    margin-bottom: 0; /* No margin for row layout */
    margin-right: 16px; /* Space between label and input */
  }

  .input-field {
    /* flex: 2; Allow input to take more space */
  }
}
