/* Root styling for the CreateQuotation component */
.CreateQuotation-component {
  width: 100%;
  padding: 20px;
  /* background-color: white; */
  /* border-radius: 8px; */
  /* box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); */
  height:95vh;
  overflow-y: scroll;
  color: #848484;
  font-family: sans-serif;
  /* position: relative; */
  /* margin-top: 1rem; */

}

.CreateQuotation-component h2 {
  /* font-size: 24px; */
  /* color: #333; */
  margin-bottom: 20px;
}
.bill-type-container {
  margin: 20px 0;
  padding: 5px;
  border-radius: 5px;
  width: 45%;
  height: 4rem;
  display: flex;
  justify-content: space-around;
  /* justify-content: center; */
  align-items: center;
}
.bill-type-container .label{
  width: 80%;
}

.CreateQuotation-component .bill-type-label {
  font-size: 16px;
  font-weight: bold;
  margin-right: 10px;
  /* color: #333; */
}

.CreateQuotation-component .bill-type-select {
  width:11rem ;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 3px;
  outline: none;
  background-color: white;
  transition: border-color 0.3s ease-in-out;
}



.CreateQuotation-component .section {
  margin-bottom: 30px;
}

.CreateQuotation-component .row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.CreateQuotation-component label {
  font-weight: bold;
  display: block;
  margin-bottom: 8px;
  font-size: 16px;
}

.CreateQuotation-component input,
.CreateQuotation-component select,
.CreateQuotation-component textarea {
  width: 100%;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  /* border-radius: 4px; */
  box-sizing: border-box;
}

.CreateQuotation-component .company-section,
.CreateQuotation-component .client-section {
  /* flex: 1; */
  margin-right: 20px;
  /* width: 40%; */
  /* height: 7rem; */
  /* height: 50%; */
  margin-bottom: 2rem;
}


.CreateQuotation-component .company-details,
.CreateQuotation-component .client-details {
  margin-top: 10px;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.CreateQuotation-component .company-details p,
.CreateQuotation-component .client-details p {
  margin: 5px 0;
  font-size: 14px;
  /* color: #555; */
}

/* Product Table Styling */
.CreateQuotation-component .product-table {
  width: 90%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.CreateQuotation-component .product-table th{
  text-align: center;
  border: 2px solid gray;
  /* color: gray */
}
.CreateQuotation-component .product-table td {
  /* padding: 10px; */
  text-align: center;
  border: 1px solid gray;
}

.CreateQuotation-component .product-table th {
  /* background-color: #f1f1f1; */
  font-weight: bold;
}

/* Adjusting input fields inside the table */
.CreateQuotation-component .product-table input[type="text"],
.CreateQuotation-component .product-table input[type="number"] {
  width: 100%;
  height: 100%;
  padding: 2px;
  font-size: 14px;
  background-color: transparent; /* Removes background color */
  border: none;                 /* Removes border */
  outline: none;                /* Removes focus outline */
  /* padding: 0.5rem;              Optional: Adds padding for spacing */
  /* font-size: 1rem;              Optional: Adjust font size */
  /* color: #333;   */
  
  /* border: 1px solid #ccc; */
  /* border-radius: 4px; */
  /* box-sizing: border-box; */
}

/* Adjusting the width of product name column */
.CreateQuotation-component .product-name-input {
  width: 10%;
  max-width: 200px; /* Ensure it doesn't exceed a certain width */
  min-width: 100px; /* Ensure a minimum width */
  /* white-space: nowrap; */
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Dropdown / Suggestions list styling */
.CreateQuotation-component .suggestions-list {
  list-style: none;
  padding: 0;
  margin: 0;
  background-color: #fff;
  width: 100%; 
  max-height: 150px;
  overflow-y: auto;
  z-index: 100; 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  /* color: #333; */
}

.CreateQuotation-component .suggestions-list li {
  padding: 8px;
  cursor: pointer;
  /* color: #333; */
  border: 1px solid rgb(232, 225, 225);
}

.CreateQuotation-component .suggestions-list li:hover {
  background-color: #f1f1f1;
}

/* Add Row and Remove Button */
.CreateQuotation-component .add-row,
.CreateQuotation-component .submit-btn {
  display: inline-block;
  /* padding: 10px 20px; */
  background-color: #C6C5B9;
  color: #848484;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}


.CreateQuotation-component .product-table td button {
  padding: 6px 10px;
  /* background-color: #535353; */
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* Terms and Conditions Styling */
.CreateQuotation-component .terms-section {
  margin-top: 20px;
  width: 90%;
  /* height: 100px; */
  font-size: 14px;
  padding: 8px;
  /* border: 1px solid #ccc; */
  border-radius: 4px;
  box-sizing: border-box;
}
.create-bill-heading{
  width: 60%;
}


/* Responsive adjustments for smaller screens */
@media screen and (max-width: 768px) {
  .CreateQuotation-component .row {
    flex-direction: column;
  }

  .CreateQuotation-component .company-section,
  .CreateQuotation-component .client-section {
    margin-right: 0;
  }

  .CreateQuotation-component .product-table th,
  .CreateQuotation-component .product-table td {
    padding: 5px;
  }
}
