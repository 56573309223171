/* AddProduct.css */
.add-product-container .product-form {
    width: 100%;
    height: 99vh; /* Set a maximum height */
    /* margin: 0 auto; */
    padding: 20px;
    /* padding-right: 50px; */
    background-color: #f9f9f9;
    /* border-radius: 10px; */
    /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); */
    overflow-y: auto; /* Enable vertical scrolling */
  
    
}

.add-product-container h2 {
    /* font-size: 24px; */
    /* color: #333; */
    /* text-align: center; */
    margin-bottom: 20px;
}

.add-product-container .form-section {
    margin-bottom: 30px;
}

.add-product-container .form-section h3 {

    /* color: #444; */
    /* margin-bottom: 10px; */
    border-bottom: 2px solid #ddd;
    /* padding-bottom: 5px; */
}

.add-product-container .form-row {
    /* display: flex; */
    /* flex-wrap: wrap; */
    /* gap: 15px; */
    /* margin-bottom: 20px; */
}

.add-product-container label {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-width: 150px;
    /* color: #555; */
}

.add-product-container input[type="text"],
.add-product-container input[type="number"],
.add-product-container textarea {
    /* width: 60%; */
    padding: 8px 10px;
    /* margin-top: 5px; */
    border: 1px solid #ccc;
    /* border-radius: 5px; */
    /* font-size: 14px; */
}

.add-product-container textarea {
    resize: none;
    min-height: 100px;
}

.add-product-container button[type="submit"] {
    width: 100%;
    /* background-color: #343a40; */
    /* color: black; */
    border: none;
    padding: 10px 0;
    /* font-size: 16px; */
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-bottom: 50px;
}



/* Dropdown Styling */
.add-product-container .dropdown {
    position: relative;
    display: inline-block;
    width: 20%;
}

.add-product-container .dropdown-button {
    background-color: #C6C5B9;
    color: #848484;
    border: none;
    padding: 10px;
    /* font-size: 14px; */
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.add-product-container .dropdown-button:hover {
    /* background-color: #343a40; */
}

.add-product-container .dropdown-button:after {
    content: '▼';
    font-size: 12px;
    margin-left: 10px;
}

/* Remove the display: none from .dropdown-content */
.add-product-container .dropdown-content {
    display: none; /* Initially hidden */
    position: absolute;
    background-color: white;
    min-width: 100%;
    max-height: 200px;
    overflow-y: auto;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    z-index: 1;
    margin-top: 5px;
    padding: 10px;
    transition: all 0.3s ease-in-out;
  }
  
  /* Show the dropdown content when active */
  .add-product-container .dropdown.active .dropdown-content {
    display: block; /* Display dropdown when active */
  }
  
/* Show the dropdown content when active */
/* .add-product-container .dropdown.active .dropdown-content {
    display: block;
} */

.add-product-container .dropdown-content label {

    color: #333;
    display: flex;
    align-items: center;
    padding: 5px;
}

.add-product-container .dropdown-content label:hover {
    background-color: #f1f1f1;
    border-radius: 3px;
}

.add-product-container .company-name {

    margin-left: 10px;
}

/* Checkbox styling */
.add-product-container .dropdown-content input[type="checkbox"] {
    margin-right: 10px;
}

.add-product-container .dropdown-content input[type="checkbox"]:checked + .company-name {
    
    color: #343a40;
}

/* Multiselect selected options display */
.add-product-container .selected-options {
    display: flex;
    flex-wrap: wrap;
    margin-top: 5px;
    gap: 5px;
}

.add-product-container .selected-options span {
    background-color: #343a40;
    color: white;
    padding: 5px;
    border-radius: 3px;
    
}


.add-product-container .company-name {
    font-size: 14px;
    margin-left: 10px;
}

/* Image fields */
.add-product-container .form-section .form-row {
    display: flex;
    align-items: center;
    /* gap: 10px; */
}

.add-product-container button[type="button"] {
    /* background-color: #f44336; */
    /* background: #535353; */

    color: #848484;
    border: none;
    padding: 8px 12px;
    /* font-size: 14px; */
    border-radius: 5px;
    cursor: pointer;
}

.add-product-container button[type="button"]:last-child {
    /* background-color: #343a40; */
}

.add-product-container button[type="button"]:last-child:hover {
    /* background-color: #343a40; */
}

.company-selection {
    display: flex;
    align-items: center;
  }
  
  .dropdown {
    position: relative;
  }
  
  .dropdown-button {
    padding: 10px;
    /* border: 1px solid #ccc; */
    cursor: pointer;
  }
  
  .dropdown-content {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 100;
    max-height: 200px;
    overflow-y: auto;
    /* border: 1px solid #ccc; */
    background: #fff;
  }
  
  .selected-companies {
    display: flex;
    flex-wrap: wrap; /* Allows wrapping if there's not enough horizontal space */
    gap: 10px;
    margin-left: 20px; /* Space between dropdown and selected companies */
  }
  
  .selected-company-box {
    padding: 5px 10px;
    /* border: 1px solid #ccc; */
    background-color: #f1f1f1;
    /* border-radius: 5px; */
    white-space: nowrap; /* Keep the text in one line */
    display: inline-block; /* Ensure each name is inside its own box */
  }
 .add-product-container button[type='submit']{
    margin-left: 40%;
    width: 20%;
 }
 .img-btn{
    width: 13%;
 }

 .loading-skeleton{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
 }