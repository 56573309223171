body, html {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
 
}

.header {
    background-color: #343a40;
    color: white;
    padding: 12px;
    text-align: center;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.dashboard-main-container {
    display: flex;
    /* margin-top: 50px; Adjust according to the header height */
    /* background-color: white; */
    background-color: #f9f9f9; 
    
}

.side-bar {
    width: 15%;
    background-color: #343a40;
    padding: 20px;
    height: 100vh;
    box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.1);
    position: fixed;
    top: 48px; /* Same as the header margin-top */
    left: 0;
    color: #343a40;
}

.side-bar div {
    padding: 10px;
    margin-bottom: 10px;
    height: 100vh;
    /* background-color: white; */
    /* color: white; */
    cursor: pointer;
    text-align: center;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.side-bar div:hover {
    /* background-color: #cbcdd0; */
}

.main-sec {
    margin-left: 15%;
    height: 100%;
    /* padding: 20px; */
    width: 100%;
    box-sizing: border-box;
    position: relative;
    top: 50px; /* Adjust according to the header height */
    background-color: #f9f9f9; 
    
}
.dashboard-container{
    /* background-color: white; */
    background-color: #f9f9f9; 
}

.logout{
    background: red;
}
.company-section {
   
    /* border-radius: 8px; */
    /* padding: 20px; */
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); Soft shadow for a card-like effect */
    /* max-width: 600px; */
    /* background-color: #f9f9f9;  */
    margin: 20px auto;
  
  }
  
  .company-section label {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 10px;
    display: block;
  }
  
  .company-section select {
    width: 80%;
    padding: 10px;
    margin-bottom: 15px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    transition: border-color 0.3s ease;
  }
  
  .company-section select:focus {
    border-color: #007bff; /* Blue highlight on focus */
    outline: none;
  }
  
  .company-details {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 6px;
    padding: 15px;
    margin-top: 15px;
  }
  
  .company-details p {
    font-size: 14px;
    margin: 5px 0;
  }
  
  .company-details strong {
    color: #333;
    font-weight: 600;
  }
  
  .company-details p:not(:last-child) {
    /* border-bottom: 1px solid #eee; */
    /* padding-bottom: 8px; */
  }
  
  @media (max-width: 768px) {
    .company-section {
      padding: 15px;
    }
  
    .company-details p {
      font-size: 13px;
    }
  }
  